<template>
      <RouterView />
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1ca692;
  height: 100%;
  background-color: #1ca692;
}
</style>
 